<template>
  <div id="user.logout.index"></div>
</template>

<script>
export default {
    name: 'user.logout.index',
    created() {
      this.$store.dispatch('logout')
    }
}
</script>

<style>

</style>